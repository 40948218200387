<!-- Created by henian.xu on 2017/10/1. -->

<template>
    <Page>
        <container
            class=""
            ref="container"
        >
            <div class="prepaid-list">
                <PrepaidItem
                    v-for="(item) in prepaidCardList"
                    :key="item.id"
                    :data="item"
                />
            </div>
            <infinite-loading @infinite="onInfinite" />
        </container>
    </Page>
</template>

<script>
export default {
    name: 'Prepaid',
    data() {
        return {
            pagination: {},
            prepaidCardList: [],
        };
    },
    methods: {
        getPrepaidCardList({ currentPage } = {}) {
            return this.$api.Buyer.Mb.PrepaidCard.data({
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
            }).then(json => {
                this.prepaidCardList = this.prepaidCardList.concat(json.data.data);
                return Promise.resolve(json);
            });
        },
        onInfinite($state) {
            this.getPrepaidCardList().then(json => {
                const result = json.data;
                this.pagination = result.pagination;
                const { currentPage, totalRows, totalPages } = this.pagination;
                if (!totalRows || currentPage === totalPages) {
                    if (totalRows) {
                        $state.loaded();
                    }
                    $state.complete();
                } else {
                    $state.loaded();
                }
            });
        },
    },
};
</script>

<style lang="scss">
.prepaid-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
}
</style>
