<!-- Created by henian.xu on 2017/10/1. -->

<template>
    <Page class="bc-g1">
        <container
            class=""
            ref="container"
        >
            <div class="pocket-money">
                <div class="icon">
                    <img src="~@/assets/images/icon/pocketMoney-icon.png">
                </div>
                <div class="label">
                    我的零钱
                </div>
                <div class="price money">
                    {{ buyerData.purseBalance | price }}
                </div>
                <Linker
                    v-if="$hasPower(['buyerBalance:recharge'])"
                    to="/pocketMoney/prepaid"
                    class="btn btn-main prepaid"
                >
                    充值
                </Linker>
                <Linker
                    v-if="$hasPower(['buyerBalance:withdraw'])"
                    to="/pocketMoney/withdraw"
                    class="btn btn-green prepaid"
                >
                    提现
                </Linker>
            </div>
            <list class="bc-t">
                <PocketMoneyItem
                    v-for="(item) in balanceLogList"
                    :key="item.id"
                    :data="item"
                />
            </list>
            <infinite-loading @infinite="onInfinite">
                <div
                    class="ta-c"
                    slot="loading"
                >
                    <div
                        class="nprogress"
                        v-if="!pagination.currentPage"
                    >
                        <div class="spinner-icon">
                            <div class="monkey" />
                            <div class="cloud01" />
                            <div class="cloud02" />
                            <div class="shadow" />
                        </div>
                        <div>加载中...</div>
                    </div>
                    <div v-else>
                        加载中...
                    </div>
                </div>
                <div slot="no-result">
                    <div class="goods-nothing-icon">
                        <i class="f-icon">&#xf03e;</i>
                    </div>
                    <div class="goods-nothing">
                        您还没有相关的记录
                    </div>
                </div>
            </infinite-loading>
        </container>
    </Page>
</template>

<script>
export default {
    name: 'PocketMoney',
    data() {
        return {
            buyerData: {},
            pagination: {},
            balanceLogList: [],
        };
    },
    methods: {
        getBuyerDetail() {
            this.$api.Buyer.Mb.Buyer.detail().then(json => {
                this.buyerData = json.data.data;
                console.log(this.buyerData);
            });
        },
        getBalanceLogList({ currentPage } = {}) {
            return this.$api.Buyer.Mb.Buyer.balanceLogList({
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
            }).then(json => {
                this.balanceLogList = this.balanceLogList.concat(json.data.data);
                return Promise.resolve(json);
            });
        },
        onInfinite($state) {
            this.getBalanceLogList().then(json => {
                const result = json.data;
                this.pagination = result.pagination;
                const { currentPage, totalRows, totalPages } = this.pagination;
                if (!totalRows || currentPage === totalPages) {
                    if (totalRows) {
                        $state.loaded();
                    }
                    $state.complete();
                } else {
                    $state.loaded();
                }
            });
        },
    },
    created() {
        this.getBuyerDetail();
    },
};
</script>

<style lang="scss">
.pocket-money {
    min-height: 2.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    text-align: center;
    padding: $padding-big * 2 $padding;
    background-color: #fff;
    > .icon {
        > img {
            width: 30%;
        }
    }
    > .label {
        font-size: 0.3rem;
    }
    > .money {
        font-size: 0.6rem;
        font-weight: bold;
        color: #000;
        &:before {
            content: '$';
            font-size: inherit;
        }
    }
    > .btn.prepaid {
        padding: $padding;
        border-radius: 0.1rem;
        margin-top: $margin-big;
        font-size: 0.3rem;
    }
}
</style>
