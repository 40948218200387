<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <Container ref="container">
            <x-form
                ref="form"
                :model="formData"
            >
                <form-item
                    label="金额："
                    prop="amount"
                    ref="amount"
                >
                    <x-input
                        type="number"
                        clear
                        placeholder="提现金额"
                        v-model="formData.amount"
                    />
                </form-item>
            </x-form>

            <div class="pa-a">
                <div
                    class="btn btn-main radius fill big"
                    @click="()=>{onSubmit()}"
                >
                    提交
                </div>
            </div>

            <div class="pa-a fs-big fw-bold">
                提现明细
            </div>
            <List>
                <WithdrawItem
                    v-for="item in dataList"
                    :key="item.id"
                    :data="item"
                />
            </List>

            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </Container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';

export default {
    name: 'Withdraw',
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            formData: {
                amount: '0.1',
            },
            dataList: [],
        };
    },
    methods: {
        getListData({ currentPage, pageSize } = {}) {
            return this.$api.Buyer.Mb.BuyerBalance.withdrawList({
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.dataList = this.dataList.concat(res.data);
                return Promise.resolve(json);
            });
        },
        async onSubmit() {
            const { formData } = this;
            if (!formData.amount) {
                this.$messageBox.tips('请填写提现金额');
                return;
            }
            await this.$messageBox.confirm('是否要确认要提现', '温馨提示');
            const { data } = await this.$api.Buyer.Mb.BuyerBalance.withdraw(formData);
            this.$messageBox.tips(data.msg);

            this.infiniteDisabled = true;
            this.dataList = [];
            this.pagination = {};
            await this.$nextTick();
            this.infiniteDisabled = false;

            console.log(formData.amount, data);
        },
    },
};
</script>

<style lang="scss">
</style>
